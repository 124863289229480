<template>
  <div class="shortVideoDetails">
    <!--      <Video v-if="videoInfo" :videoShow="true" :videoInfo="videoInfo" :index="0"></Video>-->
    <swiper
        class="video-swiper"
        :options="swiperOption"
        ref="mySwiper"
        v-show="videoList.length > 0"
    >
      <swiper-slide
          class="my_swiper_silde"
          v-for="(item, index) in videoList"
          :key="'item.id' + index"
      >
        <Video
            v-if="showVideo && item.isInitialize"
            :isAcg="isAcg"
            @openSelectLine="openSelectLine"
            :videoShow="itemIndex === index"
            :videoInfo="item"
            :index="index"
            :anthologyIndex="anthologyIndex"
            @openAntholog="openAntholog"
            @playerEnded="playerEnded"
            @reward="reward"
        />
      </swiper-slide>
    </swiper>
    <!-- 切换路线框 -->
    <van-popover v-model="isOpen" placement="bottom-end">
      <div class="lineBox">
        <div
            class="line"
            :class="{ selectedLine: item.desc == videoRoadLine.desc }"
            @click.stop="selectLine(item)"
            v-for="item in roadLines"
            :key="item.desc"
        >
          {{ item.desc }}
        </div>
      </div>
    </van-popover>

    <!-- 目录弹窗 -->
    <van-popup
        v-if="isAcg"
        v-model="directoryShow"
        position="bottom"
        :style="{ height: '426px', background: '#333333',borderRadius: '10px 10px 0 0' }"
    >
      <div class="directoryContent" v-if="videoInfo && videoInfo.contents && videoInfo.contents.length > 0">
        <div class="directoryBar">
          <div class="title">
            <span>短剧·{{videoInfo.title}}·{{ videoInfo.contents.length }}集全</span>
          </div>
          <!-- <div class="btn" @click="directoryShow = false">
       <img src="@/assets/png/popupClose.png" />
     </div> -->
        </div>
        <div class="anthologyContentBox">
            <div class="anthologyClassifyBox">
                <div @click="toggleAnthologyCompilation(key)" class="anthologyClassifyItem" :class="anthologyClassifyActiveIndex === key?'anthologyClassifyActiveItem':''" v-for="key in Math.ceil(videoInfo.contents.length / 30)" :key="key">
                    <span>{{((key - 1)) * 30 + 1}}~{{key * 30}}</span>
                </div>
            </div>

            <div class="anthologyContent">
              <div @click="toggleAnthologyCollect(index + 1 + ((anthologyClassifyActiveIndex - 1) * 30))" class="anthologyItem" :class="anthologyIndex === index?'anthologyActiveItem':''" v-for="(item,index) in videoInfo.contents.slice(((anthologyClassifyActiveIndex - 1)) * 30,anthologyClassifyActiveIndex * 30)" :key="index">
                <span>{{index + 1 + ((anthologyClassifyActiveIndex - 1) * 30)}}</span>

                <div class="label" v-if="videoInfo.contents[index + ((anthologyClassifyActiveIndex - 1) * 30)]['listenPermission'] === 0">
                  <img class="icon" src="@/assets/png/vipMarkIcon.png" alt="">
                </div>

                <div class="label" v-if="videoInfo.contents[index + ((anthologyClassifyActiveIndex - 1) * 30)]['listenPermission'] === 1">
                  <img class="icon" src="@/assets/png/goldMarkIcon.png" alt="">
                  <span class="goldValue">{{videoInfo.contents[index + ((anthologyClassifyActiveIndex - 1) * 30)]['price']}}</span>
                </div>

                <div class="label" v-if="videoInfo.contents[index + ((anthologyClassifyActiveIndex - 1) * 30)]['listenPermission'] === 2">
                  <img class="icon" src="@/assets/png/freeMarkIcon.png" alt="">
                </div>

<!--                <div class="freeLabel">-->
<!--                  <img src="@/assets/png/freeMarkIcon.png" alt="">-->
<!--                </div>-->

<!--                <div class="goldLabel">-->
<!--                  <img src="@/assets/png/goldMarkIcon.png" alt="">-->
<!--                </div>-->

              </div>
            </div>
        </div>
        <!--        <div class="anthologyContent" v-if="videoData.contents">-->
        <!--          <div-->
        <!--              v-for="(item, index) in videoData.contents"-->
        <!--              @click="anthology(item, index)"-->
        <!--              :key="index"-->
        <!--          >-->
        <!--            <div class="anthologyItem" :class="{ activeItem: activeIndex == index }">-->
        <!--              <div-->
        <!--                  class="anthologyTxt"-->
        <!--                  :class="{ activeItem: activeIndex == index }"-->
        <!--              >-->
        <!--                        <span>-->
        <!--                          {{ index + 1 }}-->
        <!--                        </span>-->
        <!--              </div>-->
        <!--              &lt;!&ndash; vip &ndash;&gt;-->
        <!--              <div class="vipLabel" v-if="item.listenPermission == 0">-->
        <!--                &lt;!&ndash;                 <span>VIP</span>&ndash;&gt;-->
        <!--                <img src="@/assets/png/vipIcon.png" alt="">-->
        <!--              </div>-->

        <!--              &lt;!&ndash; 金豆 &ndash;&gt;-->
        <!--              <div-->
        <!--                  class="goldenLabel"-->
        <!--                  v-if="item.listenPermission == 1"-->
        <!--              >-->
        <!--                &lt;!&ndash;                 <span>金豆</span>&ndash;&gt;-->
        <!--                <img src="@/assets/png/GoldenIcon.png" alt="">-->
        <!--              </div>-->

        <!--              &lt;!&ndash; 免费 &ndash;&gt;-->
        <!--              <div class="freeLabel" v-if="item.listenPermission == 2">-->
        <!--                &lt;!&ndash;                 <span>免费</span>&ndash;&gt;-->
        <!--                <img src="@/assets/png/freeIcon.png" alt="">-->
        <!--              </div>-->

        <!--              &lt;!&ndash; <div class="activePlay" v-if="activeIndex == index">-->
        <!--    <img src="@/assets/gif/activePlay.gif" />-->
        <!--  </div> &ndash;&gt;-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </van-popup>

    <!-- 新打赏弹窗 -->
    <van-popup
        position="bottom"
        v-model="rewardShow"
        :style="{ height: '152px', background: '#FFFFFF',borderRadius: '10px 10px 0 0' }"
    >
      <div class="rewardContent">
        <div class="title">选择金额</div>
        <div class="moneyList">

          <div class="moneyItem" @click="changeMoney(index)" :class="rewardActiveIndex === index?'rewardActive':''" v-for="(item,index) in rewardMoney" :key="index" >
            <span>{{item}}</span>
          </div>

          <div class="moneyItem" @click="rewardPickerShow = true">
            <span>自定义</span>
          </div>

        </div>
      </div>
    </van-popup>


    <van-popup v-model="rewardPickerShow" round position="bottom">
      <van-picker
          show-toolbar
          confirm-button-text="完成"
          :columns="getColumns()"
          @confirm="onConfirm"
          @cancel="rewardPickerShow = false"
      />
    </van-popup>


    <!-- <SelectionLinePopup
      v-if="showChangeRoadLine"
      @closeSelectLine="closeSelectLine"
      @close="showChangeRoadLine = false"
    /> -->
  </div>
</template>
<script>
import Video from "@/views/shortVideo/video";
// import {queryMediaInfoList} from "@/api/video";
import { getSessionItem } from "@/utils/longStorage";
import {queryCommunityList, queryVidInfo} from "@/api/community";
// import VerticalScreen from "@/components/Video/VerticalScreen"
import { swiper, swiperSlide } from "vue-awesome-swiper";
// import SelectionLinePopup from "@/components/SelectionLinePopup";
import { Toast } from "vant";
import { mapGetters } from "vuex";
import {queryMediaInfoList} from "@/api/video";
import {addVideoHistory} from "@/utils";
import {reward} from "@/api/mine";

export default {
  name: "shortVideo",
  components: {
    Video,
    swiper,
    swiperSlide,
    // SelectionLinePopup
  },
  computed: {
    ...mapGetters({
      videoRoadLine: "videoRoadLine", // 选择的线路
    }),
  },
  data() {
    return {
      rewardShow: false,
      rewardMoney:[10,20,30,45,60],
      rewardActiveIndex: -1,
      rewardVideoInfo:{},
      rewardPickerShow: false,
      roadLines: [],
      isOpen: false,
      videoInfo: null,
      mediaID: null,
      directoryShow: false,
      videoList: [],
      videoPage: 1, //短视频请求页码
      videoSize: 10, //一页短视频的条数
      itemIndex: 0,
      showVideo: true, // 视频是否显示
      isAcg: false,
      showChangeRoadLine: false, // 显示切换路线
      anthologyClassifyActiveIndex: 1,
      anthologyIndex: 0,
      swiperOption: {
        direction: "vertical",
        on: {
          slideChangeTransitionStart: async () => {
            this.itemIndex = this.$refs.mySwiper.swiper.activeIndex;
            this.videoInfo = this.videoList[this.itemIndex];
            //往上提前初始哈
            if(this.itemIndex - 1 >= 0){
              if (!this.videoList[this.itemIndex - 1]['isInitialize']) {
                this.$set(this.videoList,this.itemIndex - 1,await this.getMediaInfo(this.videoList[this.itemIndex - 1].id));
                // this.videoList[this.itemIndex] = await this.getMediaInfo(this.videoList[this.itemIndex].id);
              }
            }

            //往下提前初始哈
            if(this.itemIndex + 1 < this.videoList.length){
              if (!this.videoList[this.itemIndex + 1]['isInitialize']) {
                this.$set(this.videoList,this.itemIndex + 1,await this.getMediaInfo(this.videoList[this.itemIndex + 1].id));
                // this.videoList[this.itemIndex] = await this.getMediaInfo(this.videoList[this.itemIndex].id);
              }
            }

            if(!this.isAcg){
              //滑到倒数第二条数据加载下一页视频数据
              if (this.itemIndex === this.videoList.length - 2) {
                this.videoPage++;
                this.getCommunityList();
              }
              // 如果数据大于3页就删除1页数据
              if (this.videoList.length > 2 * this.videoSize) {
                this.videoList = this.videoList.splice(0, this.videoSize);
                this.itemIndex = this.itemIndex - this.videoSize;
                this.$refs.mySwiper.swiper.slideTo(this.itemIndex, 0, true);
              }
            }
          },
          slideChangeTransitionEnd: async () => {
            // this.itemIndex = this.$refs.mySwiper.swiper.activeIndex;
            // console.log("滑动结束")
            // console.log(this.itemIndex)
            // console.log(!this.videoList[this.itemIndex]['isInitialize'])
            // if (!this.videoList[this.itemIndex]['isInitialize']) {
            //   this.videoList[this.itemIndex] = await this.getMediaInfo(this.videoList[this.itemIndex].id);
            // }
            // //滑到倒数第二条数据加载下一页视频数据
            // if (this.itemIndex === this.videoList.length - 2) {
            //   this.videoPage++;
            //   this.getCommunityList();
            // }
            // // 如果数据大于3页就删除1页数据
            // if (this.videoList.length > 2 * this.videoSize) {
            //   // console.log("删除")
            //   this.videoList = this.videoList.splice(0, this.videoSize);
            //   this.itemIndex = this.itemIndex - this.videoSize;
            //   this.$refs.mySwiper.swiper.slideTo(this.itemIndex, 0, true);
            // }
          },
        },
      },
      swiperOptioncare: {
        direction: "vertical",
        on: {
          slideChangeTransitionEnd: async () => {
            // this.itemIndex = this.$refs.mySwiper.swiper.activeIndex;
            // //滑到倒数第二条数据加载下一页视频数据
            // if (this.itemIndex === this.videoList.length - 2) {
            //   this.videoPage++;
            //   this.getCommunityList();
            // }
            // // 如果数据大于3页就删除1页数据
            // if (this.videoList.length > 2 * this.videoSize) {
            //   // console.log("删除")
            //   this.videoList = this.videoList.splice(0, this.videoSize);
            //   this.itemIndex = this.itemIndex - this.videoSize;
            //   this.$refs.mySwiper.swiper.slideTo(this.itemIndex, 0, true);
            // }
          },
        },
      },
    };
  },
  created() {
    let appConfig = this.$store.getters.appConfig;
    let imgDomainList = appConfig?.sourceList?.filter(
        (item) => item.type === "VID"
    );
    if (imgDomainList && imgDomainList.length > 0) {
      this.roadLines = imgDomainList[0]?.domain;
    }
  },
  mounted() {

    if (Object.prototype.toString.call(this.$store.state.app.appConfig) === "[object String]") {
      this.rewardMoney = JSON.parse(this.$store.state.app.appConfig)?.rewardMoney ?? [];
    } else {
      this.rewardMoney = this.$store.state.app.appConfig?.rewardMoney ?? [];
    }

    let query = this.$route.query;
    if (query) {
      this.isAcg = query.isAcg == 1;
      this.getMediaInfoList();
    }
    //   this.mediaID = query.id;
    //   this.getMediaInfo();

    // }
  },
  methods: {
    addVideoHistory,
    onConfirm(value, index){
      this.rewardConfirm(this.rewardVideoInfo,value);
    },
    getColumns(){
      let arr = [];
      for(let i = 1;i<101;i++){
        arr.push(i);
      }
      return arr;
    },
    changeMoney(index){
      this.rewardActiveIndex = index;
      this.rewardConfirm(this.rewardVideoInfo,this.rewardMoney[index]);
    },
    reward(videoInfo){
      this.rewardVideoInfo = videoInfo;
      this.rewardShow = true;
    },
    // 打赏
    async rewardConfirm(videoInfo,coins) {
      let req = {
        coins: String(coins || 0),
        msg: "",
        vid: videoInfo.id,
      }
      if (!req.coins || req.coins == 0) {
        Toast('请选择或输入打赏数量');
        return;
      }
      this.$store.commit('app/SET_LOADING', true);
      try {
        let res = await this.$Api(reward, req);
        this.$store.commit('app/SET_LOADING', false);
        if (res.code === 200) {
          Toast("打赏成功");
          this.rewardShow = false;
        } else {
          Toast(res.tip || "打赏失败，请稍后再试");
        }
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
        Toast("打赏失败，请稍后再试");
      }
    },
    playerEnded(){
      if(this.isAcg){
        if(this.anthologyIndex + 1 < this.videoInfo.contents.length){
          this.anthologyIndex = this.anthologyIndex + 1;
        }
      }
    },
    toggleAnthologyCompilation(index){
      this.anthologyClassifyActiveIndex = index;
    },
    toggleAnthologyCollect(index){
      this.anthologyIndex = index - 1;
      this.directoryShow = false;
    },
    openAntholog(videoInfo){
      this.directoryShow = true;
    },
    selectLine(item) {
      this.$store.commit("video/SET_VIDEOROADLINE", item);
      Toast("切换成功");
      this.isOpen = false;
    },
    getItemVideo(item) {
      if(this.isAcg){
       return item.contents?item.contents[0]:{};
      }else{
        return item;
      }
    },
    async getMediaInfo(id) {

      let req1 = {
        mediaID: id,
      };

      let req2 = {
        videoID: id,
      };

      let res = null;
      if (this.isAcg) {
        res = await this.$Api(queryMediaInfoList, req1);
      } else {
        res = await this.$Api(queryVidInfo, req2);
      }
      res.data['isInitialize'] = true;
      // let req = {
      //   videoID: id
      // };
      // let res = await this.$Api(queryVidInfo, req);
      return res.data || {};
    },
    async getMediaInfoList() {
      // this.videoInfo = this.videoList[this.$store.getters.shortVideoIndex];
      let shortVideoIndex = this.$store.getters.shortVideoIndex;
      let shortVideoList = this.$store.getters.shortVideoList;
      let itemVideo = await this.getMediaInfo(shortVideoList[shortVideoIndex].id);
      this.itemIndex = shortVideoIndex;
      shortVideoList[shortVideoIndex] = itemVideo;
      this.videoInfo = itemVideo;
      this.videoList = shortVideoList;
      this.$refs.mySwiper.swiper.slideTo(this.itemIndex, 0, true)

      //往上提前初始哈
      if(this.itemIndex - 1 >= 0){
        if (!this.videoList[this.itemIndex - 1]['isInitialize']) {
          this.$set(this.videoList,this.itemIndex - 1,await this.getMediaInfo(this.videoList[this.itemIndex - 1].id));
        }
      }

      //往下提前初始哈
      if(this.itemIndex + 1 < this.videoList.length){
        if (!this.videoList[this.itemIndex + 1]['isInitialize']) {
          this.$set(this.videoList,this.itemIndex + 1,await this.getMediaInfo(this.videoList[this.itemIndex + 1].id));
        }
      }

      if(this.videoInfo.kind === 8){
        await this.addVideoHistory(this.videoInfo);
      }
      // let videoList = [];
      // shortVideoList.forEach(async item =>{
      //   let itemVideo = await this.getMediaInfo(item.id);
      //   videoList.push(itemVideo)
      // });
      // console.log(videoList)
      //
      // let videoList = [];
      // for(let i = 0;i < shortVideoList.length;i++){
      //   videoList[i] = await this.getMediaInfo(shortVideoList[i].id)
      // }
      // console.log(videoList);
      // let videoInfo = getSessionItem("currentWorks");
      // if (videoInfo) {
      //   this.videoInfo = JSON.parse(videoInfo);
      //   this.videoList = this.videoList.concat([this.videoInfo]);
      // }
      // await this.getCommunityList();
    },
    async getCommunityList() {
      /**
       * 帖子发现列表
       */
      let req = {
        pageNumber: this.videoPage,
        pageSize: this.videoSize,
        tag: "最新",
        reqDate: new Date().toISOString(),
        newsType: "SP",
      };
      let res = await this.$Api(queryCommunityList, req);
      if (res && res.code == 200)
        var spList = res.data.list.filter((item) => {
          return item.newsType == "SP";
        });
      if (spList.length == 0 && res.data.list.length > 0) {
        this.videoPage++;
        await this.getCommunityList();
      }
      this.videoList = this.videoList.concat(spList);
    },
    openSelectLine() {
      this.isOpen = true;
    },
    // openSelectLine() {
    //   this.showChangeRoadLine = true;
    // },
    // // 关闭线路选择
    // closeSelectLine() {
    // this.showVideo = false;
    // this.showChangeRoadLine = false;
    // this.$nextTick(() => {
    //     this.showVideo = true;
    // });
    // },
  },
};
</script>
<style lang="scss" scoped>
.lineBox {
  position: fixed;
  bottom: 180px;
  right: 50px;
  height: 89px;
  width: 100px;
  border-radius: 10px 10px 0 10px;
  background: #FFFFFF;
  //background: linear-gradient(to bottom, #99d8dc 10%, #ffffff 100%);
  .line {
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    color: #333333;
    padding: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .selectedLine {
    color: #333;
    // background: rgb(132, 164, 249);
  }
}

/deep/ .van-overlay{
  background: transparent;
}

.directoryContent {
  color: #FFF;
  font-size: 14px;
  font-weight: 400;

  .directoryBar {
    padding: 0 16px;
    margin-top: 18px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      //font-size: 18px;
      //color: rgb(0, 0, 0);
    }

    .btn {
      display: flex;
      align-items: center;

      img {
        width: 14.8px;
        height: 14.8px;
      }
    }
  }

  .anthologyContentBox {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    height: 365px;

    .anthologyClassifyBox{
      padding: 0 16px;
      margin-top: 18px;
      display: flex;
      flex-flow: wrap;

        .anthologyClassifyItem{
          background: #555;
          padding: 10px 30px;
          border-radius: 28px;
          margin-right: 11px;
          margin-top: 11px;
        }

      .anthologyClassifyActiveItem {
          background: #B09FD9;
      }
    }

    .anthologyContent{
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      gap: 7px;
      padding: 0 16px;
      margin-top: 29px;


      .anthologyItem{
        width: 43px;
        height: 43px;
        background: #555;
        border: 1px solid #555;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;
      }

      .anthologyActiveItem{
        border: 1px solid #B09FD9;
      }

      .label{
        position: absolute;
        left: 0;
        top: 0;

        .icon{
          width: 14px;
          height: 14px;
        }

        .goldValue{
          position: absolute;
          left: 0;
          top: 0;
          font-size: 10px;
          font-weight: 400;
          color: #FFF;
          width: 14px;
          height: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

    }
  }
}

.rewardContent{

  .title{
    font-size: 16px;
    font-weight: 900;
    color: #333;
    margin-left: 12px;
    margin-top: 12px;
  }

    .moneyList{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 12px;
      margin-top: 38px;

      .moneyItem{
        color: #B09FD9;
        padding: 14px;
        border-radius: 8px;
        border: 2px rgba(0, 0, 0, 0.05);
      }

      .rewardActive{
        color: #999;
        border: 1px rgba(255, 255, 255, 0.05);
        background: #F5F5F5;
      }
    }
}

.shortVideoDetails {
  height: 100%;
  width: 100%;
  background: #000000;
  position: relative;
  .video-swiper {
    height: 100%;
    width: 100%;

    .my_swiper_silde {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
